<template>
	<div class="sitedetail">
		<a-descriptions :column="4">
			<template v-slot:title>
				<a-icon
					type="arrow-left"
					style="cursor: pointer; margin-right: 8px"
					@click="$router.go(-1)"
				/>
				<span @click="$router.go(-1)" style="cursor: pointer">
					返回
				</span>
			</template>
			<a-descriptions-item label="公司简称">
				{{ companyName }}
			</a-descriptions-item>
			<a-descriptions-item label="站点名称">
				{{ info.name }}
			</a-descriptions-item>
			<a-descriptions-item label="站点位置">
				{{ info.address }}
			</a-descriptions-item>

<!-- 			<a-descriptions-item>
				<a-popconfirm
					title="确定删除?"
					ok-text="确定"
					cancel-text="取消"
					@confirm="confirmRemove"
				>
					<a href="javascript:;">删除站点</a>
				</a-popconfirm>
			</a-descriptions-item> -->
			
			<a-descriptions-item>
				<span v-if="info.link">
					已关联
				</span>
				<a href="javascript:;" @click="showModal" v-else>
					关联站点
				</a>
			</a-descriptions-item>
			
			<a-descriptions-item label="装机功率">
				{{ $utils.toFixed(info.installedPower / 1000, 0)}}kW
			</a-descriptions-item>
			<a-descriptions-item label="站点容量">
				{{ $utils.toFixed(info.capacity / 3600000, 0)}}kWh
			</a-descriptions-item>
			<a-descriptions-item label="投运时间">
				{{ (info.operationTime || "-").split(" ")[0] }}
			</a-descriptions-item>
			<a-descriptions-item>
				<!-- <a-popconfirm
					v-if="info.link"
					placement="top"
					ok-text="确定"
					cancel-text="取消"
					@confirm="showModal"
				>
					<template slot="title">
						<p>是否重新关联？</p>
					</template>
					<a href="javascript:;">
						重新关联
					</a>
				</a-popconfirm> -->
			</a-descriptions-item>
		</a-descriptions>
		<a-divider />
		
		<!-- <a-button type="primary" :disabled="!info.link" @click="goto">
			进入监控页面
		</a-button> -->

		<a-modal title="关联验证码" :visible.sync="visible" :closable="false">
			<a-row>
				<a-col :span="24" class="sitedetail_title1">
					{{ codeInfo.code }}
				</a-col>
				<a-col :span="24" class="sitedetail_title2">
					倒计时：{{ countdown | countdownFilter }}
				</a-col>
				<a-col :span="24">
					说明：请在站端轻EMS内填入验证码即可完成关联。
				</a-col>
			</a-row>
			<template slot="footer">
				<a-button type="primary" @click="okModal"> 确定 </a-button>
			</template>
		</a-modal>
	</div>
</template>

<script>
	import {
		companyList,
		verifyCode,
		powerStationSearch} from "@/api/index";
		
	export default {
		name: "SiteDetail",
		filters: {
			countdownFilter(value) {
				let minutes = Math.floor(value / 60);
				let seconds = value - minutes * 60;
				return `${minutes < 10 ? "0" + minutes : minutes}分${
					seconds < 10 ? "0" + seconds : seconds
				}秒`;
			}
		},
		data() {
			return {
				companyName: "",
				// 详情
				info: {},
				// modal
				visible: false,
				// code
				codeInfo: {},
				countdown: 0,
				// setTimeout
				timer: null
			};
		},
		created() {
			this.getInfo();
			this.getCompanyList();
		},
		methods: {
			getCode(cb) {
				verifyCode({
						companyId: this.$route.query.companyId,
						powerStationId: this.$route.query.id
					},{})
					.then(res => {
						this.codeInfo = res.data;
					})
					.finally(() => cb());
			},
			getInfo(fn) {
				powerStationSearch({
						companyId: this.$route.query.companyId,
						powerStationId: this.$route.query.id
					},{})
					.then(res => {
						this.info = res.data;
					})
					.finally(fn);
			},
			// 公司列表
			getCompanyList() {
				companyList({}).then(res => {
					const id = this.$route.query.companyId, data = res.data;
					
					//默认需要选中第1个
					if(data.length > 0 && id){
						let company = data.find((n) => n.id == id);
						
						this.companyName = company.name;
					}
				});
			},
			
			showModal() {
				if (!Object.keys(this.info).length) return;
				const hide = this.$message.loading("生成验证码中", 0);
				this.getCode(() => {
					hide();
					if (!this.codeInfo.code) {
						this.$message.info("生成失败，请重新再试");
					} else {
						this.startCountDown();
						this.visible = true;
					}
				});
			},
			// 开始倒计时
			startCountDown() {
				let count = this.$moment(this.codeInfo.expireTime).diff(
					this.$moment(),
					"seconds"
				);
				if (count <= 0) {
					this.countdown = 0;
					return;
				}
				this.countdown = count;
				clearTimeout(this.timer);
				this.timer = setTimeout(this.startCountDown, 1000);
			},
			okModal() {
				this.visible = false;
			},

			// confirmRemove() {
			// 	this.$apis.site
			// 		.remove({ data: { id: this.$route.query.id } })
			// 		.then(() => {
			// 			this.$message.success("删除成功", 0.5).then(() => {
			// 				this.$router.go(-1);
			// 			});
			// 		});
			// },

			// 关联站点跳转单站监控
			goto() {
				if (!this.info.link) {
					this.$message.info("未关联站点");
					return;
				}

				//todo
			}
		},
		beforeDestroy() {
			clearTimeout(this.timer);
			this.timer = null;
		}
	};
</script>

<style lang="scss">
	.sitedetail {
		.ant-descriptions {
			.ant-descriptions-item:nth-child(4) {
				text-align: center;
			}
		}
	}
	.sitedetail_title1 {
		font-size: 32px;
		font-weight: bold;
		text-align: center;
	}
	.sitedetail_title2 {
		margin: 8px 0 16px 0;
		font-size: 20px;
		font-weight: bold;
		text-align: center;
		color: red;
	}
</style>
